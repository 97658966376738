<template>
  <b-table
    id="order-list"
    class="position-relative mt-0"
    :items="data"
    responsive
    primary-key="id"
    :fields="fields"
    show-empty
    empty-text="No matching records found"
  >
    <template #head()="fields">
      <table-header-labels
        :fields="fields"
        :filter="filter"
        @onSort="$emit('onSort', { ...$event })"
      ></table-header-labels>
    </template>
    <template #cell(email)="data">
      <div vertical-align="center">
        <b-link
          v-if="data.item.user"
          :to="{ name: 'user', params: { id: data.item.user.id } }"
          class="font-weight-bold d-block text-nowrap m-1"
        >
          {{ data.item.user.email }}
        </b-link>
        <template v-else><div class="m-1">Deleted user</div></template>
      </div>
    </template>
    <template #cell(created_at)="data">
      {{ $dateHelpers.formatDate(data.item.created_at) }}
      {{ $dateHelpers.formatTime(data.item.created_at) }}
    </template>
    <template #cell(status)="data">
      <div :class="changeColor(data.item.status)" class="text-capitalize">
        {{ data.item.status }}
      </div>
    </template>
  </b-table>
</template>

<script>
import { BLink, BTable } from "bootstrap-vue";
import { changeTextColor } from "@/services/utils/util";
import TableHeaderLabels from "../TableHeaderLabels.vue";
export default {
  components: {
    BLink,
    BTable,
    TableHeaderLabels,
  },
  props: {
    data: {
      type: Array,
    },
    fields: {
      type: Array,
    },
    filter: {
      type: Object,
    },
  },
  methods: {
    changeColor(item) {
      return changeTextColor(item);
    },
  },
};
</script>

<style></style>
