<template>
  <div>
    <b-card no-body class="mb-0">
      <template #header>
        <multi-filter
          :filter="filter"
          @filter-change="setFilter"
        ></multi-filter>
      </template>
      <table-spinner :loading="loading"></table-spinner>
      <total-income v-if="!loading" :total="total.data"></total-income>
      <order-table
        v-if="!loading"
        :data="data"
        :fields="fields"
        :filter="filter"
        @onSort="changePage"
      >
      </order-table>
      <template #footer>
        <pagination
          :meta-data="meta"
          @change-page="changePage({ page: $event })"
          @change-per-page="changePerPage({ per_page: $event })"
        >
        </pagination>
      </template>
    </b-card>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { mapActions } from "vuex";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import TotalIncome from "@/layouts/components/TotalIncome.vue";
import OrderTable from "@/layouts/components/order/OrderTable.vue";
import MultiFilter from "@/layouts/components/PaymentFilter.vue";
import debounce from "lodash.debounce";
import { getFirstDayOfMonth } from "@/services/utils/util";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
export default {
  components: {
    BCard,
    Pagination,
    TotalIncome,
    OrderTable,
    MultiFilter,
    TableSpinner,
  },
  data() {
    return {
      fields: [
        { key: "email", label: "Email" },
        { key: "total", label: "Total" },
        { key: "currency", label: "Currency" },
        { key: "created_at", label: "Created at" },
        { key: "status", label: "Status" },
        "",
      ],
      loading: false,
    };
  },
  computed: {
    filter() {
      return {
        ...this.$route.query,
        start_date: this.$route.query?.start_date ?? getFirstDayOfMonth(),
        end_date: this.$route.query?.end_date ?? new Date().toJSON(),
        page: this.$route.query?.page ?? 1,
        per_page: this.$route.query?.per_page ?? 10,
        sort_by: this.$route.query?.sort_by ?? "user_email",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
    data() {
      return this.$store.getters["adminOrder/getOrderListData"];
    },
    meta() {
      return this.$store.getters["adminOrder/getOrderListMeta"];
    },
    total() {
      return this.$store.getters["adminOrder/getTotalIncomeData"];
    },
  },
  mounted() {
    this.getOrders({ ...this.filter }).catch(console.error);
    this.getTotalIncome({ ...this.filter }).catch(console.error);
  },
  methods: {
    ...mapActions({
      getOrders: "adminOrder/getOrders",
      getTotalIncome: "adminOrder/getTotalIncome",
    }),
    changePage(filter = {}) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshOrders();
    },
    changePerPage(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshOrders();
    },
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });

      if (filter.search !== undefined) {
        if (filter.search?.length === 0) {
          this.$router.replace({
            query: { ...this.filter, search: undefined },
          });
        }
      }

      this.onRefreshOrders();
      this.onRefreshIncome();
    },
    onRefreshOrders: debounce(async function () {
      this.loading = true;
      try {
        await this.getOrders(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),

    onRefreshIncome: debounce(async function () {
      this.loading = true;
      try {
        await this.getTotalIncome(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
  },
};
</script>

<style scoped>
.totals {
  font-size: 1.4rem;
}
</style>
